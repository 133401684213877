import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { UserPageType } from '../../../typings/organization/org-manage';
import { namespace } from 'vuex-class';
const OrganizationModule = namespace('Organization');
let OrgManage = class OrgManage extends Vue {
    constructor() {
        super(...arguments);
        this.selectedDeptDetail = {};
        this.userId = '';
        this.isReloaded = false;
        this.showUserPageType = UserPageType.UserList;
        this.UserPageType = UserPageType;
    }
    /**
     * 选中部门
     */
    async selectNode(curDeptDetail) {
        if (!curDeptDetail.id)
            return;
        this.isReloaded = false;
        await this.$nextTick();
        this.showUserPageType = UserPageType.UserList;
        this.selectedDeptDetail = curDeptDetail;
        this.isReloaded = true;
    }
    /**
     * 切换用户列表或详情页面
     */
    async toggleComponent(v) {
        this.showUserPageType = v.showUserPageType;
        this.userId = v.userDetail?.id;
        if (this.showUserPageType === UserPageType.UserList) {
            this.userTable.toggleDept = true;
            await this.userTable.setDynamicTableHeight();
            this.userTable.toggleDept = false;
        }
    }
};
__decorate([
    Ref()
], OrgManage.prototype, "orgLeft", void 0);
__decorate([
    Ref()
], OrgManage.prototype, "userTable", void 0);
__decorate([
    OrganizationModule.State('enableOrgNum')
], OrgManage.prototype, "enableOrgNum", void 0);
OrgManage = __decorate([
    Component({
        name: 'OrgManage',
        components: {
            OrgLeft: () => import('@/components/organization/org-manage/org-tree/index.vue'),
            UserTable: () => import('@/components/organization/org-manage/user-table.vue'),
            UserDetail: () => import('@/components/organization/org-manage/user-detail.vue'),
            NoEnableOrgPage: () => import('@/components/organization/org-manage/no-enable-org-page.vue'),
        },
    })
], OrgManage);
export default OrgManage;
